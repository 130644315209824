import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faQuestionCircle, faBook } from '@fortawesome/free-solid-svg-icons';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header>
        <h1>Deeply</h1>
      </header>

      <main>
        <h2 className="main-text">Learn like you mean it</h2>
        
        <div className="product-boxes">
          <a href="https://deeply-feedback.vercel.app/" className="box-link">
            <div className="box">
              <FontAwesomeIcon icon={faPencilAlt} />
              <span>Deeply Feedback</span>
              <p className="product-description">Feedback on your academic writing</p>
            </div>
          </a>
          <div className="box">
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span>Deeply Quiz</span>
            <p className="product-description">Remember what you learn</p>
          </div>
          <a href="https://deeply-grammar.vercel.app/" className="box-link">
            <div className="box">
              <FontAwesomeIcon icon={faBook} />
              <span>Deeply Grammar</span>
              <p className="product-description">Master grammar without rules</p>
            </div>
          </a>
        </div>

        <hr className="styled-hr" />

        <section className="about-section">
          <h2>About Deeply</h2>
          <p>
            Deeply is the home of educational apps developed by Jonathon Martin, an educator and innovator at the University of Helsinki. The name comes from the desire to learn ... well ... deeply, as opposed to superficially. Each app uses innovative pedagogical or technical techniques to assist students. I hope you find them useful or at least interesting.
          </p>
          <p>
            Currently there are two apps available. Deeply Feedback will give you targeted feedback on your academic writing using AI. This is currently only available to those with a University of Helsinki email address. Deeply Grammar uses inductive learning to teach you how to use articles and punctuation correctly.
          </p>
        </section>
      </main>

      <footer>
        <p>&copy; 2025 Deeply</p>
        <nav>
          <a href="#">About</a>
          <a href="#">Contact Me</a>
        </nav>
      </footer>
    </div>
  );
};

export default LandingPage;
